/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: 'Rajdhani', sans-serif;
}

a {
  color: white;
}

// clases Bootstrap
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  text-align: right;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: right;
}

//Fin Bootstrap




.reset-p {
  padding: 0
}

$verde: #8CC63F;

.block-gris {
  width: 100%;
  padding: 1.5em;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #2B2B2B;
}


.wrapper-login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-login {
  width: 600px;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  align-items: center;

  @media (max-width: 600px) {
    width: 100%;
    min-height: 100vh;
    margin-top: 30px;
  }
}

.box-login {
  width: 600px;
  margin: 2em;
  overflow: hidden;
}

.log-login {
  width: 160px;
  display: flex;
  margin: 0 auto;
}

.log-cuenta {
  width: 200px;
  margin: 0 0 0 1em;

  @media (max-width: 500px) {
    width: 160px;
    margin: 0;
  }

  @media (max-width: 410px) {
    width: 130px;
  }
}

.log-cuenta2 {
  width: 180px;
  margin: 0 0 0 1em;
}


.wrapper-maq-titles {
  display: flex;
  align-items: center;
}

.maquito {
  width: 100px
}

.content-titles-principal {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.title-bienvenido {
  font-size: 2.5em;
  color: #76af2b;
  font-weight: 700;
  margin: 1.5em 0 0;
}

.sub-title {
  font-size: .9em;
  color: #7b7b7b;
}


.wrapper-mover-elementos {
  display: flex;
  margin-right: -100%;
  transition: all ease .4s;
}

.mover-left {
  margin-right: 0;
  margin-left: -100%;
}

.back-login {
  margin-right: -100%;
  margin-left: 0;
}

.mover-elementos {
  width: 100%;
  padding: .5em;
}

.btn-back {
  width: 200px;
  margin: 0.5em 0 0.8em;
  line-height: 2.5;
  background-color: #5d5d5d;
  color: white;
  border-radius: 20px;
}

.seleccionar {
  width: 100%;

  .title-seleccion {
    font-size: 1em;
    margin: 0 0 0 2em;
    font-weight: bold;
    color: #353535;
    position: relative;

    &:before {
      content: "";
      width: 25px;
      border: 1px solid #8bc34a;
      position: absolute;
      left: -36px;
      top: 7px;
    }
  }

  .ul-seleccionar {
    width: 99%;

    .li-seleccionar {
      font-weight: bold;
      color: #888888;
      background-color: white;
      box-shadow: 0 0 2px 0 #b3b3b3;
      margin: 0 0 1em 0;
      padding: 0 0 0 1em;
      line-height: 2.8;
      border-radius: 10px;
      cursor: pointer;
      transition: all ease .3s;

      &:hover {
        padding: 0 0 0 2.5em;
        background-color: #8bc43f;
        color: white;
      }

      @media(max-width: 500px) {
        font-size: .9em;
        line-height: 2.3;
      }
    }
  }
}

.selection-and-search {
  margin: 0 0 1.5em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buscar_empresa {
  width: 100%;
  line-height: 2.5;
  background-color: #e8e8e8;
  ;
  border: 1px solid #d6d6d6;
  padding: 0 1em;
  border-radius: 17px;
}


.form-login {
  display: flex;
  flex-direction: column;
  margin: 0 0 2em 0;
}

.input-login {
  width: 100%;
  margin: 1em 0;
  padding: .8em 1.5em;
  border-radius: 20px;
  border: transparent;
  box-shadow: 0 0 2px 0 #b3b3b3;
}

::placeholder {
  font-size: 1em;
  color: rgba(123, 123, 123, .5);
}

.rename-pass,
.rename-cuenta {
  font-size: .9em;
  display: flex;
  color: #8c8c8c;
}

.rename-pass {
  justify-content: flex-end
}

.rename-cuenta {
  justify-content: center
}


.btn-sesion {
  width: 100%;
  margin: .5em 0 .8em;
  line-height: 3;
  background-color: $verde;
  color: white;
  border-radius: 20px;
  cursor: pointer;

  &:focus {
    outline: none
  }
}



// Mi cuenta
.wrapper-accordion {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 2000px) {
    max-width: 2000px;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    width: 90%;
    min-height: inherit;
    margin: 100px auto 0;
  }
}





@import "~bootstrap/dist/css/bootstrap.css";

.ul-lista-accordion {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 1045px) {
    width: 100%;
    margin-top: 60px;
  }

  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

.li-accordion {
  width: 350px;
  display: flex;
  align-items: center;
  margin: .5em;
  line-height: 3;
  background-color: white;
  box-shadow: 0 0 5px 0 #b3b3b3;
  border-radius: 10px;
  transition: all .3s;
  overflow: hidden;

  @media (max-width: 800px) {
    width: 300px;
  }

  @media (max-width: 702px) {
    width: 280px;
  }

  @media (max-width: 658px) {
    width: 100%;
    height: 60px;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    clip-path: circle(11% at 22.5% 50%);
    position: absolute;
    transition: all .5s;
    z-index: -1;

    @media (max-width: 800px) {
      clip-path: circle(0);
    }
  }
}

.li-accordion:hover:before {
  clip-path: circle(70.7% at 50% 50%);
  border-radius: 10px;
}

.icon-position {
  position: absolute;
  right: 0;
  bottom: -18px;
  font-size: 8em;
  color: white;
  transform: rotate(-16deg);
  opacity: .2;
  z-index: -1;
  transition: all 1s ease;
}

.li-accordion:hover .icon-position {
  transform: rotate(20deg);
}


.clip1:before {
  background-color: #9bda53
}

.clip2:before {
  background-color: #ff6363
}

.clip3:before {
  background-color: #b563ff
}

.clip4:before {
  background-color: #639aff
}

.clip5:before {
  background-color: #ff9b08
}

.clip6:before {
  background-color: #d48080
}

.link--cuenta {
  width: 100%;
  font-size: 1em;
  margin: 0 0 0 5em;
  display: flex;
  align-items: center;
  line-height: 1;
  color: #4d4d4d;
  font-weight: 600;
  transition: all .3s;

  &:hover {
    text-decoration: none;
    color: black;
  }

  @media (max-width: 800px) {
    margin: 0 0 0 3em;
  }

  @media (max-width: 657px) {
    font-size: 1em;
    margin: 0 0 0 1em;
  }
}

.li-accordion:hover .link--cuenta {
  color: white;
}




.link--footer {
  color: white;

  &:hover {
    text-decoration: none;
  }
}

.iconos1,
.iconos2,
.iconos3,
.iconos4,
.iconos5,
.iconos6,
.iconos7 {
  font-size: 1.3em;
  margin: 0 .5em 0 0;
  padding: .4em;
  color: white;
  border: 5px solid white;
  border-radius: 50%;

  @media (max-width: 800px) {
    font-size: 1.8em;
  }

  @media (max-width: 657px) {
    font-size: 1.4em;
  }
}

.iconos1 {
  background-color: #9bda53
}

.iconos2 {
  background-color: #ff6363
}

.iconos3 {
  background-color: #b563ff
}

.iconos4 {
  background-color: #639aff
}

.iconos5 {
  background-color: #ff9b08
}

.iconos6 {
  background-color: #d48080
}

.iconos7 {
  background-color: #58b7ae
}



.title-accordion {
  width: 100%;
  margin: 0 0 0 3em;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 8;
  color: #696969;
  text-decoration: none;

  @media (max-width: 800px) {
    margin: 0 0 0 .5em;
  }

  @media (max-width: 658px) {
    line-height: 0;
  }
}

.li-accordion:hover .title-accordion {
  color: white;
  text-decoration: none;
}



// Personal
.li-accordion-personal {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin: .5em 0;
  line-height: 3;
  background-color: white;
  box-shadow: 0 0 2px 0 #b3b3b3;
  border-radius: 10px;
  transition: all .3s;

  &:nth-child(9) {
    background-color: #dcedd4;
    color: #155724;
  }

  &:nth-child(10) {
    background-color: #dcedd4;
    color: #155724;
  }
}

.ul-lista-personal {
  width: 45%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 786px) {
    width: 100%;
  }

  @media (max-width: 657px) {
    width: 100%;
    margin-top: 80px;
  }

  @media (max-width: 500px) {
    margin-top: 0;
  }
}

.wrapper-personal {
  width: 100%;
  margin: 1.2em;
  display: flex;
  align-items: center;
  // background-color: tomato;
}

.title--bold {
  font-weight: bold;
  margin: 0 0 0 1em;
}


.img-personal {
  width: 50px;
}

.content-personal {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 2em;
}

.title-personal {
  font-size: 2.5em;
  margin: 0;
  font-weight: bold;
  color: #4c4c4c;
}

.subtitle-personal {
  font-size: 1em;
  margin: 0;
  font-weight: bold;
  color: #8bc34a;
}

.txt--date {
  font-weight: 100;
  margin: 0 0 0 .5em;
}


.mostrar-footer {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 2;
  background-color: #2B2B2B;
  cursor: pointer;
  transition: all .3s;

  @media (max-width: 900px) {
    right: 20px;
  }

  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
    bottom: 20px;
  }
}

.girar {
  transform: rotate(180deg)
}

.subir {
  bottom: 135px;

  @media (max-width: 700px) {
    bottom: 85px;
  }
}

.block-footer {
  width: 100%;
  position: fixed;
  z-index: 1;
  padding: 3em 0;
  left: 0;
  bottom: -180px;
  background-color: #2B2B2B;
  transition: all .3s;

  @media (max-width: 700px) {
    padding: 1.2em 0;
  }

  // @media (max-width: 600px) {
  //     bottom: -2px;
  // }
}

.ver-menu {
  bottom: -2px;
}


.animate__animated.animate__fadeInRight {
  --animate-duration: 1s;
}

.animate__animated.animate__fadeInLeft {
  --animate-duration: .7s;
}

// footer
.animate__animated.animate__fadeInUp {
  --animate-duration: 1s;
}




.flecha-icon {
  color: white;
  font-size: 2.8em;

  @media (max-width: 600px) {
    font-size: 2em;
  }
}


.content-icon-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-footer {
  color: white;
  font-size: 2.5em;
  margin: .2em 0;
  transition: all .3s ease;

  &:hover {
    transform: scale(1.3);
  }

  @media (max-width: 600px) {
    font-size: 2em;
  }
}

.title-icon {
  margin: 0 2em;
  font-size: 1em;
  color: white;
  display: flex;
  text-align: center;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 700px) {
    font-size: .8em;
  }

  @media (max-width: 600px) {
    margin: 0 1.5em;
  }
}

// Cotizaciones
.content-block-header {
  display: flex;
}

.header-coti {
  width: 100%;
  padding: 1.5em 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #2B2B2B;
}

.search {
  width: 600px;
  padding: 0 0 0 2em;
  background-color: #0e0e0e;
  border-radius: 20px;
  line-height: 3;
  color: white;
  text-align: center;
}

::placeholder {
  color: #6f6f6f;
}

.wrapper-header-coti {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.wrapper-header-servicio {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn-view-filters {
  font-size: .9em;
  padding: 0 1em;
  margin: 0 1em 0 0;
  line-height: 2.5;
  color: white;
  font-weight: bold;
  display: inline-flex;
  border: 3px solid #8CC63F;
  border-radius: 10px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background-color: #8CC63F;
    color: #2B2B2B;
  }

  @media (max-width: 420px) {
    font-size: .8em;
    border: 2px solid #8CC63F;
  }
}

/* BONTON rojo filtro*/
.btn-view-filters-red {
  font-size: .9em;
  padding: 0 1em;
  margin: 0 1em 0 0;
  line-height: 2.5;
  color: white;
  font-weight: bold;
  display: inline-flex;
  border: 3px solid #b81e1e;
  border-radius: 10px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background-color: #b81e1e;
    color: #2B2B2B;
  }

  @media (max-width: 420px) {
    font-size: .8em;
    border: 2px solid #b81e1e;
  }
}

/* BONTON rojo filtro*/
.burger {
  display: flex;
  color: white;
  font-size: 2.5em;
  cursor: pointer;
  z-index: 1;
}

.class-home {
  border-radius: 50%;
  padding: .5em;
  margin: 0 .5em 0 0;
  font-size: .6em;
  box-shadow: inset 0 0 0 35px #8bc34a;
  transition: box-shadow 0.3s, transform 0.3s;
  transform: scale3d(0.9, 0.9, 1);

  &:hover {
    box-shadow: inset 0 0 0 3px #8bc34a;
    color: #8bc34a;
    transform: scale3d(1, 1, 1);
  }

  @media (max-width: 420px) {
    font-size: .53em;
    border: 2px solid #8CC63F;
  }
}


.menu {
  margin: 0 1em 0 0;
  cursor: pointer;

  @media (max-width: 500px) {
    margin: 0;
  }
}

.linea-1,
.linea-2,
.linea-3 {
  width: 40px;
  height: 4px;
  background-color: #8CC63F;
  margin: 7px 0px;
  border-radius: 10px;
  transition: 0.4s ease-out;
}

.change .linea-1 {
  transform: rotate(-46deg) translate(-2px, 3px);
  position: absolute;
}

.change .linea-2 {
  opacity: 0;
}

.change .linea-3 {
  transform: rotate(48deg) translate(-4px, -7px);
}




// activa scroll para tablas en mobile
.scroll-eje-x {
  padding: 0 1em;
  // overflow-x: scroll;
  position: relative; // anteponer a la clase ºsk
}

// .scroll-eje-x::-webkit-scrollbar{
//     height: 5px;
// }
// .scroll-eje-x::-webkit-scrollbar-thumb{
//     background-color: #a0e220;
//     border-radius: 10px;
// }

.ancho-mobile {
  @media (max-width: 768px) {
    width: 1000px;
  }
}

.ancho-mobile2 {
  @media (max-width: 768px) {
    width: 500px;
  }
}


.txt-total {
  font-weight: bold;
}

.btn-ver {
  font-size: .8em;
  font-weight: bold;
  color: #8bc34a;
  border: 2px solid #8bc34a;
  padding: .4em 2em;
  border-radius: 20px;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    background-color: #8bc34a;
    color: white;
  }
}


.panel-left-emergent {
  width: 50%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: -50%;
  z-index: 99;
  background-color: #1b1b1b;
  transition: all .3s;

  @media (max-width: 500px) {
    width: 100%;
    left: -100%;
  }
}


.mostrar-panel-left {
  left: 0%;
  position: fixed;
}

.wrapper-list-left {
  width: 80%;
  margin: 3em auto;
  margin-top: 180px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  //border: 1px solid tomato;
}

.list-left {
  width: 100%;
}

.li-list {
  color: white;
  padding: 1em 0;
  border-bottom: 1px solid #4e4e4e4a;

  &:hover {
    color: #baf547;
    text-decoration: none;
  }
}

.icon-derecha {
  font-size: 1.2em;
  margin: 0 .5em 0 0;
}

.paint1 {
  color: #baf547
}

.paint2 {
  color: #FFC400
}

.paint3 {
  color: #ff1f1f
}

.paint4 {
  color: #00c3ff
}

.paint5 {
  color: #ff00f6
}

.paint6 {
  color: #FFED14
}

.paint7 {
  color: #46e3a9
}

.paint8 {
  color: #8ea5f1
}


.txt-hover {
  cursor: pointer;
  color: white;

  &:hover {
    color: #baf547;
    text-decoration: none;
  }
}

.wrapper-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.opacidad {
  background-color: #000000ab;
  z-index: 1;
  transition: all 0.3s ease;
}

.panel-right-emergent {
  width: 30%;
  min-height: 100vh;
  position: absolute;
  z-index: 9;
  right: -30%;
  background-color: #1b1b1b;
  transition: all .3s;

  @media (max-width: 1000px) {
    width: 50%;
    right: -50%;
  }

  @media (max-width: 500px) {
    width: 100%;
    right: -100%;
  }
}


.mostrar-panel-right {
  right: 0;
  position: fixed;
}

.list-right {
  margin: 3em;
  margin-top: 180px;
}


.content-maquinas-general {
  padding: 8em 0 0;

  @media (max-width: 1000px) {
    padding: 7em 0 8em;
  }
}

.wrapper-titles {
  width: 100%;
  padding: 2em 0;
  display: flex;
  justify-content: center;
  position: relative; // anteponer a la clase wrapper-mask

  @media (max-width: 700px) {
    padding: 3em 0 1em;
  }

  @media (max-width: 575px) {
    padding: 1em 0;
  }
}


.title-start-color1,
.title-start-color2,
.title-start-color3,
.title-start-color4,
.title-start-color5,
.title-start-color6,
.title-start-color7 {
  width: 60%;
  padding: .8em 0;
  border-radius: 30px;
  text-align: center;
  font-size: 1.1em;
  font-weight: 600;

  @media (max-width: 700px) {
    width: 90%;
  }

  @media (max-width: 600px) {
    font-size: .9em;
  }
}

.title-start-color1 {
  background-color: #a0e220;
  color: #577d0e
}

.title-start-color2 {
  background-color: #FFC400;
  color: #886800
}

.title-start-color3 {
  background-color: #ff1f1f;
  color: #7d1313
}

.title-start-color4 {
  background-color: #00c3ff;
  color: #006280;
}

.title-start-color5 {
  background-color: #FFED14;
  color: #96602b
}

.title-start-color6 {
  background-color: #A0E346;
  color: #547f19
}

.title-start-color7 {
  background-color: #8ea5f1;
  color: #2b4bb7
}



.contenedor-tabla {
  width: 93%;
  height: auto;
  margin: 10em auto 0;
  padding: 1em 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 7px #b5b5b5;
  overflow-y: scroll;
  // @media (max-width: 790px) {
  //     height: 660px;
  // }
  // @media (min-width: 790px) {
  //     height: 660px;
  // }
  // @media (max-width: 575px) {
  //     width: 90%;
  //     margin: 8em auto 7em;
  // }
}

.contenedor-tabla::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.contenedor-tabla::-webkit-scrollbar-thumb {
  background-color: #767676;
  border-radius: 5px;
}

.posicion-top {
  background-color: white;
  position: sticky;
  z-index: 1;
  top: -16px;
}

.alinear-derecha {
  text-align: right;
}

.link-element {
  cursor: pointer;
  position: relative;
  font-weight: bold;

  &:before {
    content: "";
    width: 50px;
    border-bottom: 2px solid #a0e220;
    position: absolute;
    top: 36px;
  }

  // &:after{
  //     content: "";
  //     width: 6px;
  //     height: 6px;
  //     border-radius: 50%;
  //     background-color: #a0e220;
  //     position: absolute;
  //     top: 34px;
  //     left: 117px;
  // }
}

// Inicio Componente Switch
.font-20 {
  font-size: 15px
}

.switch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  display: inline-block;
  line-height: 0;
  margin: 0;
}

.switch input {
  margin: 0;
  margin-right: 0.5em;
  font-size: 1em;
  visibility: hidden;
  width: 4em;
  height: 2em;
  vertical-align: middle;
}

.switch .slider {
  will-change: background-color;
  content: "";
  position: absolute;
  cursor: pointer;
  width: 4em;
  height: 2em;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bdc3c7;
  transition: 0.4s;
  border-radius: 1em;
}

.switch .slider:after {
  will-change: transform;
  cursor: pointer;
  content: "";
  position: absolute;
  background-color: #fff;
  height: 1.7em;
  width: 1.7em;
  left: 0.15em;
  top: 0.15em;
  border-radius: 50%;
  transition: 0.4s;
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.3);
}

.switch .slider:active:after {
  transform: scale(0.9);
}

.switch input:checked+.slider {
  background-color: #8bc34a;
}

.switch input:checked+.slider:after {
  transform: translateX(2em);
}

.switch input:checked+.slider:active:after {
  transform: translateX(2em) scale(0.9);
}

.switch.square .slider {
  border-radius: 0;
}

.switch.square .slider:after {
  border-radius: 0;
}

.switch.line .slider {
  background: none;
  height: 0.2em;
}

.switch.line .slider:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.8em;
  margin: auto;
  background-color: #bdc3c7;
  height: 0.2em;
  width: 4em;
}

.switch.line .slider:after {
  left: -0.1em;
}

.switch.line input:checked+.slider {
  background: transparent;
}

.switch.line input:checked+.slider:before {
  background-color: #3498db;
}

.switch.line input:checked+.slider:after {
  transform: translateX(2.43em);
}

.switch.line input:checked+.slider:active:after {
  transform: translateX(2.43em) scale(0.9);
}

// Fin Componente Switch



// Inicio MIS MAQUINAS
.wrapper--maquinas{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // background-color: tomato;
}

.wrapper--modelos{
    width: 100%;
    padding: 9em 0 3em 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    overflow: hidden;
    @media (max-width: 599px) {
        padding: 6em 0 0;
    }
}


// OVERLAY
.wrapper-overlay {
  width: 100%;
  min-height: 100vh;
  background-color: #212121;
  position: absolute;
  top: 0;
  z-index: 1;
  display: none;
  transition: all .3s;

  @media (max-width: 744px) {
    padding: 0 0 7em 0;
  }
}

.mostrar-overlay {
  display: block
}

.close-overlay {
  display: none
}




.content--maquinas {
  width: 350px;
  padding: 1.7em 0;
  margin: .4em;
  display: flex;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .4);
  overflow: hidden;

  @media (max-width: 744px) {
    width: 80%;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
}

.box-maquinas {
  width: 42%;
  height: 100%;
  margin: 0 .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: tomato;
}

.box-titles-maquinas {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.img-ajustar {
  width: 100%;
  border-radius: 10px;
}

.lista-maquinas {
  margin: 0 0 0 .5em;
}

.titulo-maquinas {
  font-size: .9em;
  font-weight: bold;
  line-height: 1.2;
}

.color-maquinas {
  font-weight: bold;
  color: #19D900;
}

.btn-seleccionar {
  width: 90%;
  font-size: .8em;
  text-transform: uppercase;
  margin: .5em 0;
  padding: .3em;
  border: 2px solid #19D900;
  color: #19D900;
  background-color: transparent;
  font-weight: bold;
  border-radius: 10px;

  &:hover {
    background-color: #19D900;
    color: white;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.botones-maquinas {
  display: flex;

  @media (max-width: 381px) {
    flex-direction: column;
  }
}

.btn-ficha,
.btn-historicos2 {
  margin: .5em .3em;
  padding: .6em .8em;
  font-size: .7em;
  font-weight: 600;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  transition: all .3s;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (max-width: 381px) {
    width: 90%;
    font-size: .6em;
  }
}

.btn-ficha {
  background-color: #90d83c;
  color: white;
}

.btn-historicos2 {
  background-color: #9a9a9a;
  color: white;
}

// Fin MIS MAQUINAS



// inicio Historicos
.content-box-img-txt-izq {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #eee;
}

.img-izq {
  width: 300px;
  margin: 0 1em;
}


.content-box-img-txt-der {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  // background-color: #ccc;
  @media (max-width: 599px) {
    height: inherit;
    padding: 0 0 2em 0;
    align-items: flex-start;
  }
}

.content-txt-der {
  width: 100%;
  // border: 1px solid tomato;
}

.line-bottom {
  padding: .4em 0;
  border-bottom: 1px solid #dcdcdc;
}

.svg-proveedor {
  width: 100px;
}

.title-bold {
  font-weight: bold;
  color: #676767;
}

.line-top {
  border-top: 30px solid #2B2B2B;
}

.logotipo-historico {
  width: 130px;
  display: flex;
  margin: 1em auto;
}

.content-tables {
  width: 100%;
  min-height: 50vh;
  padding: 1em 0 10em 0;
  background-color: #ececec;
}

.wrapper-tables {
  display: flex;
  background-color: white;
  border-radius: 5px;
  margin: 1em 0;
  padding: 1em 0;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .3);
}

.centrar-listas {
  width: 94%;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
}

.table-izq {
  width: 20%;
  margin: 0 0 0 2em;
  // background-color: tomato;
}

.table-der {
  width: 80%;
  // background-color: greenyellow;
}

.txt-fechas {
  font-weight: bold;
}

.txt-number {
  font-weight: bold;
  color: #19D900;
  cursor: pointer;
}

// fin historicos


// SELECT
.container ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}



.marcar-opcion {
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #eaeaea;
}

ul li input[type=radio] {
  position: absolute;
  visibility: hidden;
}

ul li label {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 300;
  font-size: 1em;
  padding: 0 25px 0 80px;
  margin: 10px auto;
  height: 40px;
  // z-index: 9;
  cursor: pointer;
}

ul label {
  color: #505050;
}

ul li .check {
  display: block;
  position: absolute;
  border: 3px solid #AAAAAA;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 20px;
  left: 20px;
  // z-index: 5;
  transition: border .25s linear;
}

ul li:hover .check {
  border: 3px solid #505050;
}

ul li .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 10px;
  width: 10px;
  top: 2px;
  left: 2px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type=radio]:checked~.check {
  border: 3px solid #8bc34a;
}

input[type=radio]:checked~.check::before {
  background: #8bc34a;
}

input[type=radio]:checked~label {
  color: #8bc34a;
  font-weight: 600;
}

.signature {
  margin: 10px auto;
  padding: 10px 0;
  width: 100%;
}

.signature p {
  text-align: center;
  font-family: Helvetica, Arial, Sans-Serif;
  font-size: 0.85em;
  color: #AAAAAA;
}

.signature .much-heart {
  display: inline-block;
  position: relative;
  margin: 0 4px;
  height: 10px;
  width: 10px;
  background: #AC1D3F;
  border-radius: 4px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.signature .much-heart::before,
.signature .much-heart::after {
  display: block;
  content: '';
  position: absolute;
  margin: auto;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #AC1D3F;
  top: -4px;
}

.signature .much-heart::after {
  bottom: 0;
  top: auto;
  left: -4px;
}

.signature a {
  color: #AAAAAA;
  text-decoration: none;
  font-weight: bold;
}


.wrapper--check--servicios {
  width: 700px;
  display: flex;
  flex-direction: column;
  margin: 8em auto 0;
  position: relative;

  @media (max-width: 777px) {
    width: 100%;
  }
}

.content--check-servicios {
  background-color: white;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0 0 4px #d8d8d8;

  @media (max-width: 575px) {
    margin-top: 0;
  }
}

.content-seleccion {
  width: 100%;
  padding: .8em;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #656565;
  color: white;
}

.title-check {
  font-size: 1em;
  font-weight: 600;
  margin: 0 0 0 1em;
}

// OVERLAY
.wrapper-overlay {
  width: 100%;
  min-height: 100vh;
  background-color: #212121;
  top: 0;
  z-index: 1;
  display: none;
  transition: all .3s;

  @media (max-width: 744px) {
    position: absolute;
  }
}

.mostrar-overlay {
  display: block
}

.close-overlay {
  display: none
}

.content-overlay {
  width: 80%;
  margin: 9em auto 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;

  // background-color: tomato;
  @media (max-width: 1170px) {
    width: 70%;
  }

  @media (max-width: 900px) {
    width: 90%;
  }
}

.search-element {
  width: 90%;
  height: 30px;
}

.content--maquinas--overlay {
  width: 330px;
  padding: 1.3em 0;
  margin: .7em;
  display: flex;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .4);

  @media (max-width: 744px) {
    width: 80%;
  }

  @media (max-width: 500px) {
    width: 95%;
  }
}

.cerrar-modal {
  font-size: 2.3em;
  position: absolute;
  top: 0px;
  color: white;
  left: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (max-width: 700px) {
    top: 80px;
  }

  @media (max-width: 600px) {
    top: 70px;
    color: white;
    background-color: #34d829;
    border-radius: 50%;
  }
}


.btn-check {
  width: 100%;
  margin: 2em 0;
  line-height: 3;
  border: 3px solid #505050;
  text-transform: uppercase;
  font-weight: 600;
  color: #505050;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: #505050;
    color: white;
  }

  &:focus {
    outline: none
  }
}

.wrapper-selection-machine {
  width: 100%;
  margin: 1em 0 2em;
  display: flex;
  justify-content: center;
  background-color: white;
  border-left: 12px solid #19d900;
  box-shadow: 0 0 4px #c7c7c7;
  border-radius: 8px;
}

.content-selection-machine {
  width: 100%;
  margin: 1em 0;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.img-selection-machine {
  width: 150px;
}

.circle-machine {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: -1;
    background-color: #19D900;
  }
}

.title-selection-machine {
  width: 100%;
}

.title1 {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;

  @media (max-width: 470px) {
    font-size: 1.2em;
  }
}

.title2 {
  margin: 0;
  font-size: 1.3em;
  font-weight: bold;
  color: #19D900;
}

.title3 {
  margin: 0;
  font-size: 1em;
}

.line-hr {
  margin: .5em 0;
}


.wrapper--message {
  margin: 1em 0;
}

.wrapper-table-horario {
  background-color: white;
  padding: 2em 0 0;
  border-radius: 10px;
  box-shadow: 0 0 4px #d8d8d8;
}

.wrapper-horarios {
  width: 100%;
  height: 300px;
  margin: 1em 0;
  background-color: tomato;
}



// Fechas
.wrapper--fechas {
  width: 80%;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.color-title-fecha {
  margin: 0;
  color: white;
}

.margen-fech {
  margin: 0 .3em 0 0;
}

input {
  color: #656565
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  margin: 0 0 3em 0;
  -webkit-appearance: listbox;
  background-color: transparent;
  border-bottom: 1px solid #4e4e4e;
}

.active-color-fecha {
  color: #baf547;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: transparent;
  background: url(/assets/img/down.svg) center/80% no-repeat;
  cursor: pointer;
  position: relative;
  top: -12px;
  font-size: .8em;
}

// Check
.color-check {
  width: 100%;
  color: #eee;
  cursor: pointer;
}

.estado {
  margin: 0 0 1em 0;
  color: white;
  border-bottom: 1px solid #4e4e4e;
}


// Selección multiple
$textColor: #1e2330;
$primary: #8bc34a;

.wrapper-selection-multiply {
  margin: 0 0 2em 0;
}

.content-scroll {
  height: 100px;
}

.selectMultiple {
  position: relative;

  select {
    display: none;
  }

  &>div {
    position: relative;
    z-index: 2;
    padding: 8px 12px 2px 12px;
    border-radius: 8px;
    background: #0c0c0c;
    font-size: 14px;
    min-height: 44px;
    box-shadow: 0 4px 16px 0 rgba(#162a5a, 0.12);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 4px 24px -1px rgba(#162a5a, 0.16);
    }

    .arrow {
      right: 1px;
      top: 0;
      bottom: 0;
      cursor: pointer;
      width: 28px;
      position: absolute;

      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 2px;
        height: 8px;
        border-bottom: 8px solid #8bc34a;
        top: 43%;
        transition: all 0.3s ease;
      }

      &:before {
        right: 12px;
        transform: rotate(-130deg);
      }

      &:after {
        left: 9px;
        transform: rotate(130deg);
      }
    }

    span {
      width: 100%;
      color: #99a3ba;
      display: block;
      position: absolute;
      left: 12px;
      cursor: pointer;
      top: 8px;
      line-height: 28px;
      transition: all 0.3s ease;

      &.hide {
        opacity: 0;
        visibility: hidden;
        transform: translate(-4px, 0);
      }
    }

    a {
      position: relative;
      padding: 0 24px 6px 8px;
      line-height: 28px;
      color: $textColor;
      display: inline-block;
      vertical-align: top;
      margin: 0 6px 0 0;

      em {
        font-style: normal;
        display: block;
        white-space: nowrap;
      }

      &:before {
        content: "";
        left: 0;
        top: 0;
        bottom: 6px;
        width: 100%;
        position: absolute;
        display: block;
        background: rgba(#e4ecfa, 0.7);
        z-index: -1;
        border-radius: 4px;
      }

      i {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 28px;
        display: block;

        &:before,
        &:after {
          content: "";
          display: block;
          width: 2px;
          height: 10px;
          position: absolute;
          left: 50%;
          top: 50%;
          background: white;
          border-radius: 1px;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      &.notShown {
        opacity: 0;
        transition: opacity 0.3s ease;

        &:before {
          width: 28px;
          transition: width 0.45s cubic-bezier(0.87, -0.41, 0.19, 1.44) 0.2s;
        }

        i {
          opacity: 0;
          transition: all 0.3s ease 0.3s;
        }

        em {
          opacity: 0;
          color: #8bc34a;
          transform: translate(-6px, 0);
          transition: all 0.4s ease 0.3s;
        }

        &.shown {
          opacity: 1;

          &:before {
            width: 100%;
            background-color: black;
          }

          i {
            opacity: 1;
          }

          em {
            opacity: 1;
            transform: translate(0, 0);
          }
        }
      }

      &.remove {
        &:before {
          width: 28px;
          transition: width 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44) 0s;
        }

        i {
          opacity: 0;
          transition: all 0.3s ease 0s;
        }

        em {
          opacity: 0;
          transform: translate(-12px, 0);
          transition: all 0.4s ease 0s;
        }

        &.disappear {
          opacity: 0;
          transition: opacity 0.5s ease 0s;
        }
      }
    }
  }

  &>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    border-radius: 8px;
    transform: translate(0, 20px) scale(0.8);
    transform-origin: 0 0;
    filter: drop-shadow(0 12px 20px rgba(#162a5a, 0.08));
    transition: all 0.4s ease,
      transform 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44),
      filter 0.3s ease 0.2s;

    li {
      color: $textColor;
      font-size: .9em;
      color: #bfbfbf;
      background: #0e0e0e;
      padding: 12px 16px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      transition: background 0.3s ease, color 0.3s ease,
        transform 0.3s ease 0.3s, opacity 0.5s ease 0.3s,
        border-radius 0.3s ease 0.3s;

      &:first-child {
        border-radius: 8px 8px 0 0;

        &:last-child {
          border-radius: 8px;
        }
      }

      &:last-child {
        border-radius: 0 0 8px 8px;

        &:first-child {
          border-radius: 8px;
        }
      }

      &:hover {
        background: $primary;
        color: #fff;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6px;
        height: 6px;
        background: rgba(#000, 0.4);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%, -50%);
        transform-origin: 50% 50%;
      }

      &.beforeRemove {
        border-radius: 0 0 8px 8px;

        &:first-child {
          border-radius: 8px;
        }
      }

      &.afterRemove {
        border-radius: 8px 8px 0 0;

        &:last-child {
          border-radius: 8px;
        }
      }

      &.remove {
        transform: scale(0);
        opacity: 0;

        &:after {
          animation: ripple 0.4s ease-out;
        }
      }

      &.notShown {
        display: none;
        transform: scale(0);
        opacity: 0;
        transition: transform 0.35s ease, opacity 0.4s ease;

        &.show {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

  &.open {
    &>div {
      box-shadow: 0 4px 20px -1px rgba(#162a5a, 0.12);

      .arrow {
        &:before {
          transform: rotate(-50deg);
        }

        &:after {
          transform: rotate(50deg);
        }
      }
    }

    &>ul {
      transform: translate(0, 12px) scale(1);
      opacity: 1;
      visibility: visible;
      filter: drop-shadow(0 16px 24px rgba(#162a5a, 0.16));
    }
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  25% {
    transform: scale(30, 30);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(50, 50);
  }
}

// Center & dribbble
// .selectMultiple {
//     margin-top: -7.5%;
// }
.dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
  opacity: 0.5;
  transition: all 0.4s ease;

  &:hover {
    opacity: 1;
  }

  img {
    display: block;
    height: 36px;
  }
}


// Graficos
.content-overflow {
  overflow-x: scroll;
}

.wrapper-graficos {
  width: 900px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  // z-index: 1;
}

.content-graficos {
  background-color: white;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0 0 16px #bdbdbd;

  @media (max-width: 575px) {
    padding: 1em;
    box-shadow: inherit;
  }
}


// Lector Qr
.fondo-qr {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b1b1b;
}

.border-qr {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}


.img-blink {
  width: 180px;
  position: absolute;
  animation: lector .5s infinite alternate;

  @keyframes lector {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @media (max-width:500px) {
    width: 130px;
  }
}

.component-camara {
  display: flex;
  flex-direction: column;
  margin: 0 .5em 0 0;
  position: absolute;
  left: -80px;

  @media(max-width: 800px) {
    width: 100%;
    justify-content: center;
    flex-direction: row;
    top: 30px;
    left: 0;
  }

  @media(max-width:500px) {
    top: -80px;
  }
}

.margen-icons-cam {
  margin: .3em;
}

//  Hoja
.fondo-hoja {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #dedede;
}

//  Hoja
.fondo-hoja-pdf {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #525659;
}

// .wrapper-hoja{
//     width: 100%;
// }

.wrapper-pdf {
  width: 100%;
  min-height: 100vh;
}

.dropdown-btn {
  color: #7d7d7d;
}



.contenido-pdf {
  width: 100%;
  min-height: 100vh;
  margin-top: 97px;

  @media (max-width: 500px) {
    margin-top: 110px;
  }
}

.content-button-pdf {
  width: 100%;
  padding: 1em 0 2em 0;
  background-color: #525659;
}

.btn-back-machine,
.btn-back-machine2 {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 3;
  color: white;
  background-color: #8cc63f;
  border-radius: 10px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.btn-back-machine {
  margin: 2em auto 0;
}

.btn-back-machine2 {
  width: 300px;
  margin: 4em auto 0;
}






.remover-line {
  &:hover {
    text-decoration: none;
  }
}

.icon-back {
  width: 20px;
  margin: 0 .3em 0 0;
}


















.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  font-size: 18px;
  color: #60666d;

  &__current {
    position: relative;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    cursor: pointer;
    outline: none;

    &:focus {
      &+.select-box__list {
        opacity: 1;

        // We have to set "animation-name: none;" to make the list visible (read below how it works)

        animation-name: none;

        .select-box__option {
          cursor: pointer;
        }
      }

      .select-box__icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 13px;
    transition: 0.2s ease;
  }

  &__value {
    display: flex;
  }

  &__input {
    display: none;

    &:checked+.select-box__input-text {
      display: block;
    }
  }

  &__input-text {
    font-size: .8em;
    display: none;
    width: 100%;
    margin: 0;
    padding: 15px;
    background-color: #0c0c0c;
    color: #c7c7c7;
    border-radius: 8px;
  }

  &__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;

    // We need to use animation with delay.
    // Otherwise the click event will not have time to run on label, because this element disapears immediately when .select-box__current element loses the focus.
    // This delay will not be noticed because we set "opacity" to "0".
    // We also use "animation-fill-mode: forwards" to make the list stay hidden.

    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
  }

  &__option {
    font-size: .8em;
    display: block;
    margin: 0;
    padding: 15px;
    background-color: #0e0e0e;
    display: flex;

    &:hover,
    &:focus {
      color: white;
      background-color: #8bc34a;
    }
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

.title--estados {
  color: white;
  font-size: .8em;
}
